<template>
  <v-container>
      <v-row class="Header">
        <v-col cols="12">
          <h1>{{ Header }}</h1>
        </v-col>
      </v-row>
      <survey :survey="survey" />
  </v-container>
</template>

<script>
import * as Survey from "survey-vue";
import "survey-vue/survey.css";
import "./index.css";
import * as SurveyPDF from "survey-pdf";
import axios from "axios";
const { convert } = require('html-to-text');

Survey.StylesManager.applyTheme("orange");
// Working with vue-js-excel package to see if could download as excel file.
//import { VueJsExcel } from '../functions/vue-js-excel'

const generatePDF = (survey, opts, title) => {
    // if the save was successful to SurveyJS
    if (opts.success) {
        if (confirm("Do you want a PDF copy?")) {
          // Set the parameters for the PDF format
          let options = { fontSize: 12, commercial: true, 
            margins: { left: 10, right: 10, top: 10, bot: 10 },
            format: [210, 297]
          };

          // htmlRenderAs: 'auto', 
          // let converter = new showdown.Converter();
          // surveyPDF.onTextMarkdown.add(function(survey, options) {
          //     var str = converter.makeHtml(options.text);
          //     str = str.substring(3);
          //     str = str.substring(0, str.length - 4);
          //     options.html = str;
          // });
          let surveyPDF = new SurveyPDF.SurveyPDF(survey.toJSON(), options);
          surveyPDF.mode = "display"
          surveyPDF.data = survey.data;
          surveyPDF.save(`${title} - Survey Results.pdf`)
        }
      }
}

export default {
  props: {
    Header: {
      Type: String,
      Default: "This is the default title", // seconds
    },
    id: {
      Type: String,
      Required: true,
    },
    Postid: {
      Type: String,
      Required: true,
    },
    Contactid: {
      Type: Number,
      Default: 0,
    },
  },
  name: "SurveyJS",
  data() {
    return {
      survey: null,
    };
  },
  created() {
    // Get a valid token
    this.GetToken()

    // Load new Survey from SurveyJS
    this.survey = new Survey.Model({ surveyId: this.id });

    // check to see if we need to save to SurveyJS
    if (this.Postid !=  0) {
      // Load the Postid to save data to in SurveyJS
      this.survey.surveyPostId = this.Postid;
    }

    // Optionally, show saving progress and 
    // show an error and "Save Again" button if the results can't be stored.
    // this.survey.surveyShowDataSaving = true;

    // Load Survey with parameters
    this.loadSurvey()
  },
  computed: {},
  watch: {},
  methods: {
    GetToken: () => {
        return new Promise((resolve, reject) => {
            let payload = { system: "OUG_Survey" };
            const baseURL = "https://server.ownurgoal.net.au/auth/OUG_Check_System";
            axios.post(baseURL, payload)
                .then((response) => {
                    axios.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${response.data.token}`;
                    resolve(response.data.token)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    },
    loadSurvey() {
      
      // Display message while loading survey
      Survey.surveyStrings.loadingSurvey =
        "Please wait. we are loading the form…";
      
      // Display message while saving survey
      Survey.surveyStrings.savingData =
        "Please wait. We are validating and saving your response.";

      // Display message if saving survey error occurs
      Survey.surveyStrings.savingDataError = "We had trouble saving your entries";

      // Display message when survey has successfully saved
      Survey.surveyStrings.savingDataSuccess =
         "We saved your information sucessfully - Thank you !";
      
      // Display message to try again to save survey after load failure
      Survey.surveyStrings.saveAgainButton = "Try to save again.";

      // Display a preview before proceeding to a payment screen
      //Survey.showPreviewBeforeComplete = "showAnsweredQuestions";

      // Use the below to register any functions referenced in the survey
      //Survey.FunctionFactory.Instance.register("checkVoucher", checkVoucher);

      // Run when the user has successfully completed the survey
      this.survey.onComplete.add((survey) => {
        // Add the survey id before sending to OUG
        this.SaveSurvey(survey, this.id, this.Header, this.Contactid)

      });
    },
    async SaveSurvey(survey, id, title, Contactid) {
          
            // Save the result locally
            localStorage.setItem("Result", JSON.stringify(survey.data));
            // console.log(id, Contactid)
            if (survey.data.SurveyToken) {
                
                const toastId = this.$toast(`Creating PDF to add to your profile - Please Wait!`, {
                    timeout: false, closeButton: false,
                });

                // Load the PDF onto the server
                const filename = await this.loadFiletoOUG(survey)

                // if Save to OUG Contact Details for the Contact
                this.savetoOUGDocuments(survey, id, title, Contactid, filename, toastId);

            } else {

                // if Save to OUG Survey JS Data
                this.savetoOUG(survey, id, title, Contactid);

                // On Send Result to SurveyJS
                survey.onSendResult.add( function(survey, opts) {
                  generatePDF(survey, opts, title) ;
                });

            }
    },
    loadFiletoOUG(survey ) {
      return new Promise((resolve, reject) => {
          // Set the parameters for the PDF format
          let options = { fontSize: 10, commercial: true, 
            margins: { left: 10, right: 10, top: 10, bot: 10 },
            format: [210, 297]
          };

          let surveyPDF = new SurveyPDF.SurveyPDF(survey.toJSON(), options);
          surveyPDF.mode = "display"
          surveyPDF.data = survey.data;
          // Convert all HTML to comment type for more consistent display
          var questions = surveyPDF.getAllQuestions();
          let panel, index = "";
          for (var i = 0; i < questions.length; i++) {
              var obj = questions[i];
              if (obj.getType() == 'html') {
                  const text = convert(obj.processedHtml, {
                    wordwrap: 115
                  });
                  let newQuestion = Survey.Serializer.createClass('comment');
                  newQuestion.name = obj.name;
                  panel = obj.parent;
                  index = panel.elements.indexOf(obj);
                  panel.removeElement(obj);
                  panel.addElement(newQuestion, index);
                  surveyPDF.setValue(newQuestion.name, text)
              }
          }
          surveyPDF.raw('blob').then( (file) => { 
              let fdataobj = new FormData();
              fdataobj.append("UploadFile", file);
              const baseURL = "https://server.ownurgoal.net.au/file/uploadSingleFile";
              axios.post(baseURL, fdataobj, { 'Content-Type': 'multipart/form-data' })
              .then((response) => {
                  // Get File name here
                  let path = response.data.filename
                  let startpos = path.lastIndexOf("/")
                  resolve(path.substr((startpos+1),60))
              })
              .catch((error) => {
                  reject(error);
              });
          });
      });
    },
    savetoOUGDocuments(survey, id, title, Contactid, filename, toastId) {
      return new Promise((resolve, reject) => {
        let  jsonDetails = {
                  "Contact_Details_id": 0,
                  "Contact_id": Contactid,
                  "Type": 3189,
                  "Description": title,
                  "Details": filename 
              }
        let oParsedObject = {
              "Payload": jsonDetails,
              "Table": "Contact_Details",
              "Primary_Key": "Contact_Details_id",
              "Action": "INSERT"
          };

        // Call Axios function to do the conversion
        const baseURL = "https://server.ownurgoal.net.au/gen/EditTable";
        // Add Survey to OUG Documents
        axios
            .post(baseURL, oParsedObject)
            .then((response) => {
                // Dismiss Toast Message
                this.$toast.dismiss(toastId);
                this.$toast.success(`Document has been saved to your profile on OwnUrGoal!`, {
                    timeout: 1000,
                });
                // Save to Database
                this.savetoOUG(survey, id, title, Contactid, filename)
                resolve(response.data)

            })
            .catch((error) => {
              this.$toast.dismiss(toastId);
              this.$toast.error(`A pdf copy to your responses could not be generated - Please ensure you have the latest version of the OUG App!`, {
                  timeout: 1000,
              });
              reject(error);
            });
      });
    },
    savetoOUG(survey, id, title, Contactid, filename) {
      return new Promise((resolve, reject) => {
        let payload = {}
        payload.Survey_id = id;
        payload.Survey = title ;
        payload.Contact_id = Contactid ;
        if (filename) {
          payload.Filename = filename ;
        }
        payload.Payment_id = 'Payment Not Required'
        // Strip out images before loading in Database
        for (let key in survey.data) {
          if (typeof survey.data[key]=='string') {
            if (survey.data[key].substr(0,21) == 'data:image/png;base64') {
              survey.setValue(key, "Image Received")
            }
          }
        }
        payload.Survey_Data = survey.data ;
        // Call Axios function to do the conversion
        const baseURL = "https://server.ownurgoal.net.au/gen/AddSurvey";
        // Add Survey to OUG DB
        axios
            .post(baseURL, payload)
            .then((response) => {
                this.$toast.success(`Results has been saved to OwnUrGoal Database!`, {
                    timeout: 1000,
                });
                resolve(response.data)
            })
            .catch((error) => {
                this.$toast.error(`There was a problem saving your results - OUG Support will be notified!`, {
                    timeout: 1000,
                });
                reject(error);
            });
      });
    },
  },
  
};
</script>

<style scoped>

.Header {
  text-align: center;
  margin-bottom: 5px;
  margin-top: 15px;
  color: grey;
}


.pay-button {
  text-align: center;
  background-color: green;
  color: white;
  font-size: 15px;
  margin-top: 25px;
}
</style>
