<template>
    <v-card 
        class="pa-1 mx-auto" 
        elevation="24">
        <div id="surveyElement">
          <Survey :Header="Title" :id="ID" :Postid="PostID" :Contactid="ContactID" />
        </div>
    </v-card>
</template>

<script>
import Survey from "../components/RunSurvey";

export default {
  name: "RunSurvey",
  components: {
    Survey,
  },
  data() {
    return {
      Title: this.$route.params.title,
      ID: this.$route.params.id,
      PostID: this.$route.params.postid,
      ContactID: this.$route.params.contactid,
    };
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped></style>
